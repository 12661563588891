export const rootServers: ReadonlySet<string> = new Set([
  'a.root-servers.net',
  'b.root-servers.net',
  'c.root-servers.net',
  'd.root-servers.net',
  'e.root-servers.net',
  'f.root-servers.net',
  'g.root-servers.net',
  'h.root-servers.net',
  'i.root-servers.net',
  'j.root-servers.net',
  'k.root-servers.net',
  'l.root-servers.net',
  'm.root-servers.net',
]);
